<template>
  <v-container>
    <validation-observer ref="observer" v-slot="{ invalid }">
      <!-- メッセージ -->
      <div>新しいメールアドレスを入力してください。</div>
      <div>新しいメールアドレス宛にメールアドレス変更コードをお送りします。</div>
      <div class="mb-4">
        コードの有効期限は30分間となっておりますので、速やかにご入力をお願いします。
      </div>

      <!-- 現在のメールアドレス -->
      <div>
        <v-text-field
          class="pointer-events-none"
          :value="currentEmail"
          label="現在のメールアドレス"
          outlined
          dense
          readonly
        >
        </v-text-field>
      </div>

      <!-- 新しいメールアドレス -->
      <div>
        <validation-provider v-slot="{ errors }" name="新しいメールアドレス" rules="required|email">
          <v-text-field
            v-model="newEmail"
            label="新しいメールアドレス"
            type="email"
            outlined
            dense
            :error-messages="errors"
          >
          </v-text-field>
        </validation-provider>
      </div>

      <!-- 新しいメールアドレス（確認用） -->
      <div>
        <validation-provider
          v-slot="{ errors }"
          name="新しいメールアドレス（確認用）"
          :rules="`required|email_confirm:${newEmail}`"
        >
          <v-text-field
            v-model="newEmailConfirm"
            label="新しいメールアドレス（確認用）"
            type="email"
            outlined
            dense
            :error-messages="errors"
          >
          </v-text-field>
        </validation-provider>
      </div>

      <!-- 送信ボタン -->
      <v-btn
        color="primary"
        elevation="0"
        block
        :disabled="invalid || isProcessing"
        @click="sendEmailChangeMail"
      >
        送信
      </v-btn>
    </validation-observer>
  </v-container>
</template>

<script>
import { MESSAGES } from "@/const/message";
import errorHandlerMixin from "@/mixins/errorHandlerMixin";
import { mapActions } from "vuex";
export default {
  name: "EmailChange",
  mixins: [errorHandlerMixin()],
  data: () => ({
    // 新しいメールアドレス
    newEmail: "",

    // 新しいメールアドレス（確認用）
    newEmailConfirm: "",

    // メールアドレス変更ダイアログ
    emailChangeDialog: {
      show: false,
    },

    // 処理中フラグ
    isProcessing: false,
  }),
  computed: {
    // 現在のメールアドレス
    currentEmail() {
      return this.$store.state.user.selfUser?.email;
    },
  },
  methods: {
    ...mapActions("api", ["incrementRunningApiCount", "decrementRunningApiCount"]),

    // メールアドレス変更メール送信
    async sendEmailChangeMail() {
      try {
        this.isProcessing = true;
        this.incrementRunningApiCount();

        // コードを生成し、ドキュメントのUUIDを取得
        const func = this.$httpsCallable(this.$functions, "emailchange");
        const res = await func({ newEmail: this.newEmail, newEmailConfirm: this.newEmailConfirm });

        this.decrementRunningApiCount();

        this.$router.push(`/email-change-confirm/${res.data.codeId}`).catch(() => {});
      } catch (err) {
        if (err.message === "auth/email-used") {
          this.showError(MESSAGES.ERRORS.EMAIL_USED);
        } else {
          this.showError(MESSAGES.ERRORS.UNEXPECTED);
        }
      } finally {
        this.isProcessing = false;
      }
    },
  },
};
</script>
