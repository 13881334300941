import Error404 from "@/components/pages/404";
import Chat from "@/components/pages/Chat";
import ChatGroupList from "@/components/pages/ChatGroupList";
import ChatGroupMembers from "@/components/pages/ChatGroupMembers";
import EmailChange from "@/components/pages/EmailChange";
import EmailChangeComplete from "@/components/pages/EmailChangeComplete.vue";
import EmailChangeConfirm from "@/components/pages/EmailChangeConfirm.vue";
import Graph from "@/components/pages/Graph";
import Input from "@/components/pages/Input";
import List from "@/components/pages/List";
import Login from "@/components/pages/Login";
import PasswordChange from "@/components/pages/PasswordChange";
import PasswordResetting from "@/components/pages/PasswordResetting";
import PasswordResettingComplete from "@/components/pages/PasswordResettingComplete";
import PasswordResettingConfirm from "@/components/pages/PasswordResettingConfirm.vue";
import Setting from "@/components/pages/Setting";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import Vue from "vue";
import VueRouter from "vue-router";
Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "/login",
    component: Login,
  },
  {
    path: "/chat-group-list",
    name: "/chat-group-list",
    component: ChatGroupList,
    meta: { requiresAuth: true, menuId: "chat" },
  },
  {
    path: "/chat/:groupId",
    name: "/chat",
    component: Chat,
    props: (route) => ({ groupId: route.params.groupId }),
    meta: { requiresAuth: true, menuId: "chat" },
  },
  {
    path: "/chat-group-members/:groupId",
    name: "/chat-group-members",
    component: ChatGroupMembers,
    props: (route) => ({ groupId: route.params.groupId }),
    meta: { requiresAuth: true, menuId: "chat" },
  },
  {
    path: "/input",
    name: "/input",
    component: Input,
    meta: { requiresAuth: true, menuId: "input" },
  },
  {
    path: "/list",
    name: "/list",
    component: List,
    meta: { requiresAuth: true, menuId: "list" },
  },
  {
    path: "/graph",
    name: "/graph",
    component: Graph,
    meta: { requiresAuth: true, menuId: "graph" },
  },
  {
    path: "/setting",
    name: "/setting",
    component: Setting,
    meta: { requiresAuth: true, menuId: "setting" },
  },
  {
    path: "/email-change",
    name: "/email-change",
    component: EmailChange,
    meta: { requiresAuth: true, menuId: "setting" },
  },
  {
    path: "/email-change-confirm/:codeId",
    name: "/email-change-confirm",
    component: EmailChangeConfirm,
    props: (route) => ({ codeId: route.params.codeId }),
    meta: { requiresAuth: true, menuId: "setting" },
  },
  {
    path: "/email-change-complete",
    name: "/email-change-complete",
    component: EmailChangeComplete,
    meta: { requiresAuth: true, menuId: "setting" },
  },
  {
    path: "/password-resetting",
    name: "/password-resetting",
    component: PasswordResetting,
  },
  {
    path: "/password-resetting-confirm/:codeId",
    name: "/password-resetting-confirm",
    component: PasswordResettingConfirm,
    props: (route) => ({ codeId: route.params.codeId }),
  },
  {
    path: "/password-resetting-complete",
    name: "/password-resetting-complete",
    component: PasswordResettingComplete,
  },

  {
    path: "/password-change",
    name: "/password-change",
    component: PasswordChange,
    meta: { requiresAuth: true, menuId: "setting" },
  },
  {
    path: "/:pathMatch(.*)*",
    name: "/404",
    component: Error404,
    meta: { isErrorPage: true },
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
  scrollBehavior() {
    return { x: 0, y: 0 };
  },
});

const getCurrentUser = () => {
  return new Promise((resolve, reject) => {
    const unsubscribe = onAuthStateChanged(
      getAuth(),
      (user) => {
        unsubscribe();
        resolve(user);
      },
      reject
    );
  });
};

router.beforeEach(async (to, from, next) => {
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    if (await getCurrentUser()) {
      next();
    } else {
      next("/");
    }
  } else {
    next();
  }
});

export default router;
