import moment from "moment";

const getters = {};

const getDefaultState = () => ({
  chatGroups: [],
});

const state = getDefaultState();

const mutations = {
  // チャットグループ追加
  addChatGroups: (state, { id, name, uids, patientUid }) => {
    state.chatGroups.push({
      id,
      name,
      uids,
      patientUid,
      // 最新メッセージ
      latestMessage: {
        doc: null,
        datetime: "",
        message: "",
      },

      // 最終読み取り日時
      lastReadDatetime: null,

      // 未読メッセージ数
      unreadMessageCount: 0,
    });
  },

  // チャットグループの名称・参加ユーザーの更新
  updateChatGroupsNameAndIds: (state, { id, name, uids }) => {
    const chatGroup = state.chatGroups.find((cg) => cg.id === id);
    chatGroup.name = name;
    chatGroup.uids = uids;
  },

  // 最新メッセージ設定
  setLatestMessage: (state, { groupId, doc, datetime, message }) => {
    const index = state.chatGroups.findIndex((chatGroup) => chatGroup.id == groupId);

    if (index != -1) {
      state.chatGroups.splice(index, 1, {
        ...state.chatGroups[index],
        latestMessage: {
          doc,
          // TODO: 日付フォーマットの共通化（本日・昨日・それ以降でフォーマットを出し分ける）
          datetime: moment(datetime?.toDate()).format("HH:mm"),
          message,
        },
      });
    }
  },

  // 最新メッセージ設定削除
  removeLatestMessage: (state, { groupId }) => {
    const target = state.chatGroups.find((cg) => cg.id === groupId);
    const latestMessage = target.latestMessage;

    latestMessage.doc = null;
    (latestMessage.datetime = ""), (latestMessage.message = "");
  },

  // 最終読み取り日時設定
  setLastReadDatetime: (state, { groupId, lastReadDatetime }) => {
    const index = state.chatGroups.findIndex((chatGroup) => chatGroup.id == groupId);

    if (index != -1) {
      state.chatGroups.splice(index, 1, {
        ...state.chatGroups[index],
        lastReadDatetime,
      });
    }
  },

  // 未読メッセージ数設定
  setUnreadMessageCount: (state, { groupId, unreadMessageCount }) => {
    const index = state.chatGroups.findIndex((chatGroup) => chatGroup.id == groupId);

    if (index != -1) {
      state.chatGroups.splice(index, 1, {
        ...state.chatGroups[index],
        unreadMessageCount,
      });
    }
  },

  // stateの初期化
  resetState: (state) => {
    Object.assign(state, getDefaultState());
  },
};

const actions = {
  addChatGroups: ({ commit }, props) => {
    commit("addChatGroups", props);
  },
  updateChatGroupsNameAndIds: ({ commit }, props) => {
    commit("updateChatGroupsNameAndIds", props);
  },
  setLatestMessage: ({ commit }, props) => {
    commit("setLatestMessage", props);
  },
  removeLatestMessage: ({ commit }, props) => {
    commit("removeLatestMessage", props);
  },
  setLastReadDatetime: ({ commit }, props) => {
    commit("setLastReadDatetime", props);
  },
  setUnreadMessageCount: ({ commit }, props) => {
    commit("setUnreadMessageCount", props);
  },
  resetState: ({ commit }) => {
    commit("resetState");
  },
};

export default {
  namespaced: true,
  getters,
  state,
  mutations,
  actions,
};
