<template>
  <v-app>
    <!-- ヘッダーメニュー -->
    <v-app-bar class="bg-gradient-to-r from-cyan-800 to-blue-800" app dark dense elevation="0">
      <v-row class="items-center">
        <!-- 左メニュー -->
        <v-col cols="3" class="text-left">
          <!-- 戻るボタン -->
          <v-icon v-show="showBackButton" @click="goBack"> mdi-chevron-left </v-icon>
        </v-col>

        <!-- タイトル -->
        <v-col cols="6" class="text-center cursor-pointer" @click="goChatGroupMembers">{{
          title
        }}</v-col>

        <!-- 右メニュー -->
        <v-col cols="3" class="text-right">
          <span class="text-xs text-gray-300">Ver. {{ version }}</span>
        </v-col>
      </v-row>
    </v-app-bar>

    <v-main>
      <!-- メイン -->
      <router-view :key="$route.fullPath" />

      <!-- フッターメニュー -->
      <v-bottom-navigation
        v-if="loggedIn && !isErrorPage"
        class="elevation-0 border-t h-0"
        app
        color="primary"
        mandatory
        grow
        v-model="activeMenu"
      >
        <v-btn value="chat" color="white" x-large @click="onClickMenu('chat-group-list')">
          <span>交換日記</span>
          <v-badge color="red" :value="unreadMessageCount" :content="unreadMessageCount">
            <v-icon>mdi-book-open-blank-variant-outline</v-icon>
          </v-badge>
        </v-btn>

        <v-btn value="list" color="white" x-large @click="onClickMenu('list')">
          <span>記録一覧</span>
          <v-icon>mdi-format-list-bulleted </v-icon>
        </v-btn>

        <v-btn v-if="isPatient" value="input" color="white" x-large @click="onClickMenu('input')">
          <span>記録</span>
          <v-icon>mdi-pencil</v-icon>
        </v-btn>

        <!-- TODO 実装するかどうか含めて検討中、メニューからのみ削除-->
        <!--
          <v-btn value="graph" color="white" x-large @click="onClickMenu('graph')">
            <span>グラフ</span>
            <v-icon>mdi-chart-line</v-icon>
          </v-btn>
        -->

        <v-btn value="setting" color="white" x-large @click="onClickMenu('setting')">
          <span>設定</span>
          <v-icon>mdi-cog</v-icon>
        </v-btn>
      </v-bottom-navigation>
    </v-main>

    <!-- ローディング -->
    <v-overlay v-show="$store.state.api.runningApiCount > 0" class="loading-overlay" opacity="0.2">
      <v-progress-circular color="primary" size="120" width="10" indeterminate />
    </v-overlay>

    <!-- スナックバー -->
    <v-snackbar
      v-model="$store.state.snackbar.snackbar"
      class="bottom-16"
      :color="$store.state.snackbar.color"
      :timeout="$store.state.snackbar.timeout"
    >
      <span class="whitespace-pre">{{ $store.state.snackbar.text }}</span>
      <template v-slot:action="{ attrs }">
        <v-btn v-bind="attrs" icon small @click="closeSnackbar">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </template>
    </v-snackbar>

    <!-- フッター -->
    <v-footer v-if="!loggedIn && !isErrorPage" padless class="bg-primary block">
      <div class="my-4">
        <ul class="flex flex-col sm:flex-row sm:justify-center sm:space-x-6 space-y-2 sm:space-y-0">
          <li>
            <a @click="termsDialog.show = true">利用規約</a>
          </li>
          <li>
            <a @click="privacyDialog.show = true">プライバシーポリシー</a>
          </li>
          <li>
            <a @click="goToContact">お問い合わせ</a>
          </li>
        </ul>
      </div>
      <v-row no-gutters>
        <v-col class="mb-4 text-center">
          <span class="text-sm"
            >© Department of Cardiovascular Medicine and Hypertension, KAGOSHIMA UNIVERSITY Graduate
            School of Medical and Dental Sciences.</span
          >
        </v-col>
      </v-row>
    </v-footer>

    <!-- 利用規約ダイアログ -->
    <v-dialog v-model="termsDialog.show" max-width="480">
      <terms-dialog v-if="termsDialog.show" @close="termsDialog.show = false"></terms-dialog>
    </v-dialog>

    <!-- プライバシーポリシーダイアログ -->
    <v-dialog v-model="privacyDialog.show" max-width="480">
      <privacy-dialog
        v-if="privacyDialog.show"
        @close="privacyDialog.show = false"
      ></privacy-dialog>
    </v-dialog>

    <!-- 外部サイト移動確認ダイアログ -->
    <v-dialog v-model="externalSiteConfirmDialog.show" max-width="480">
      <confirm-dialog
        v-if="externalSiteConfirmDialog.show"
        title="外部サイトへ移動します。よろしいですか？"
        @ok="toExternalSite"
        @cancel="externalSiteConfirmDialog.show = false"
      ></confirm-dialog>
    </v-dialog>
  </v-app>
</template>

<script>
import Confirm from "@/components/dialogs/Confirm";
import Privacy from "@/components/dialogs/Privacy";
import Terms from "@/components/dialogs/Terms";
import errorHandlerMixin from "@/mixins/errorHandlerMixin";
import fcmTokenMixin from "@/mixins/fcmTokenMixin";
import userMixin from "@/mixins/userMixin";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import {
  collection,
  doc,
  getCountFromServer,
  getDocs,
  getFirestore,
  limit,
  onSnapshot,
  orderBy,
  query,
  where,
} from "firebase/firestore";
import { mapActions } from "vuex";
fcmTokenMixin;

export default {
  name: "App",
  mixins: [userMixin(), errorHandlerMixin(), fcmTokenMixin()],
  components: { ConfirmDialog: Confirm, TermsDialog: Terms, PrivacyDialog: Privacy },
  data: () => ({
    version: require("../package.json").version,

    // UID
    uid: null,

    // 利用規約ダイアログ
    termsDialog: {
      show: false,
    },

    // プライバシーポリシーダイアログ
    privacyDialog: {
      show: false,
    },

    // 外部サイト移動確認ダイアログ
    externalSiteConfirmDialog: {
      targetUrl: "",
      show: false,
    },
  }),
  computed: {
    // パス
    path() {
      return this.$route.path;
    },

    // 自身が患者かどうか
    isPatient() {
      return this.$store.state.user.selfUser?.type == "patient";
    },

    // アクティブ状態のメニュー
    activeMenu: {
      get: function () {
        return this.$route.meta?.menuId ?? "";
      },
      set: () => {},
    },

    // タイトル
    title() {
      switch (this.$route.path.split("/")[1]) {
        case "":
          return "心不全交換日記";

        case "chat-group-list":
          return "交換日記";

        case "chat": {
          // urlからグループIDを抽出
          const groupId = this.$route.path.split("/")[2];

          const chatGroup = this.$store.state.chat.chatGroups.find(
            (chatGroup) => chatGroup.id == groupId
          );
          return chatGroup ? `${chatGroup?.name}（${chatGroup?.uids.length}）` : "";
        }

        case "chat-group-members":
          return "メンバー一覧";

        case "input":
          return "毎日の記録";

        case "list":
          return "記録一覧";

        case "graph":
          return "グラフ";

        case "setting":
          return "設定";

        case "email-change":
        case "email-change-confirm":
        case "email-change-complete":
          return "メールアドレス変更";

        case "password-change":
          return "パスワード変更";

        case "password-resetting":
        case "password-resetting-confirm":
        case "password-resetting-complete":
        case "new-password-setting":
          return "パスワード再設定";

        default:
          return "心不全交換日記";
      }
    },

    // 戻るボタンを表示するか
    showBackButton() {
      if (this.$route.path.split("/").length > 2) {
        // チャット画面の場合はチャットグループが複数ある場合のみ戻るボタン表示
        if (this.$route.path.split("/")[1] === "chat") {
          const { chatGroups } = this.$store.state.chat;

          return chatGroups.length > 1;
        }
        return true;
      }

      switch (this.$route.path.split("/")[1]) {
        case "chat-group-members":
        case "email-change":
        case "email-change-confirm":
        case "password-change":
        case "password-resetting-confirm":
        case "new-password-setting":
          return true;
        default:
          return false;
      }
    },

    // ログイン済みか
    loggedIn() {
      switch (this.$route.path.split("/")[1]) {
        case "":
        case "password-resetting":
        case "password-resetting-confirm":
        case "password-resetting-complete":
        case "new-password-setting":
          return false;

        default:
          return true;
      }
    },

    // 未読件数
    unreadMessageCount() {
      let chatGroups = [...this.$store.state.chat.chatGroups];

      // 現在チャット画面を開いている場合は未読件数の集計を除外
      // NOTE: チャット画面を開いているときに一瞬未読バッジが表示されるのを防止する
      if (this.$route.path.split("/").length > 2) {
        if (this.$route.path.split("/")[1] === "chat") {
          const chatGroupId = this.$route.path.split("/")[2];
          chatGroups = chatGroups.filter((chatGroup) => chatGroup.id !== chatGroupId);
        }
      }

      const unreadCount = chatGroups.reduce(
        (sum, chatGroup) => sum + chatGroup.unreadMessageCount,
        0
      );

      // 未読件数をFlutter側へもセット
      if (window.navigator.userAgent.indexOf("WV_app") != -1) {
        window.flutter_inappwebview.callHandler("badgeCounterUpdate", unreadCount);
      }

      return unreadCount;
    },

    // エラーページであるかどうか
    isErrorPage() {
      return this.$route.meta.isErrorPage;
    },
  },
  created() {
    // ユーザーの認証ステータスを購読
    const auth = getAuth();

    onAuthStateChanged(auth, async (user) => {
      // ログイン時や、既にログインしている場合
      if (user) {
        this.incrementRunningApiCount();

        // UID を設定
        this.uid = user.uid;

        // 自身のユーザー情報を取得
        // この後の購読処理にて病院情報が必要な場合に備え、先に取得しておく
        await this.getSelfUser(this.uid);

        // 自身のユーザー情報を購読
        this.addUnsubscribe({ unsubscribe: this.subscribeSelfUser(this.uid) });

        // チャットグループを購読
        this.db = getFirestore();
        this.$store.state.chat.chatGroups = [];
        this.addUnsubscribe({ unsubscribe: this.subscribeGroups() });

        // FCM トークンを更新
        this.updateFcmToken(this.uid);

        // アプリの表示状態が変わった際に呼び出されるイベントリスナーを追加
        document.addEventListener("visibilitychange", this.onVisibilityChange);

        this.decrementRunningApiCount();

        // ログイン後の画面遷移
        if (this.path == "/") {
          this.$router.push(this.isPatient ? "/input" : "/list").catch(() => {});
        }
      } else {
        // 各種購読を解除
        this.unsubscribes();
        // stateを初期化
        this.resetState();
        // アプリの表示状態が変わった際に呼び出されるイベントリスナーを削除
        document.removeEventListener("visibilitychange", this.onVisibilityChange);
      }
    });
  },
  destroyed() {
    // 各種購読を解除
    this.unsubscribes();
  },

  methods: {
    ...mapActions("api", ["incrementRunningApiCount", "decrementRunningApiCount"]),
    ...mapActions("chat", [
      "addChatGroups",
      "updateChatGroupsNameAndIds",
      "setLatestMessage",
      "removeLatestMessage",
      "setLastReadDatetime",
      "setUnreadMessageCount",
    ]),
    ...mapActions("unsubscribe", ["addUnsubscribe", "addUserUnsubscribe", "removeUserUnsubscribe"]),
    ...mapActions(["resetState"]),
    ...mapActions("user", ["setMember", "setDoctor", "removeUser"]),
    ...mapActions("snackbar", ["closeSnackbar"]),
    // チャットグループ購読
    subscribeGroups() {
      const _query = query(
        collection(this.db, "chatGroups"),
        where("uids", "array-contains-any", [this.uid])
      );

      return onSnapshot(
        _query,
        (snapshot) => {
          snapshot.docChanges().forEach((change) => {
            const doc = change.doc;

            switch (change.type) {
              case "added": {
                const { name, uids, patientUid } = doc.data();
                this.addChatGroups({ id: doc.id, name, uids, patientUid });

                // 最終読み取り日時を購読
                this.addUnsubscribe({ unsubscribe: this.subscribeLastReadDatetime(doc.id) });

                // 最新メッセージを購読
                this.addUnsubscribe({ unsubscribe: this.subscribeLatestMessage(doc.id) });

                // チャットグループに属するユーザー情報を購読(購読解除登録はメソッド内にて)
                uids.forEach((uid) => {
                  this.subscribeUser(uid);
                });

                break;
              }

              case "modified": {
                const uid = doc.id;
                const { name, uids } = doc.data();

                // チャットグループ取得
                const storeChatGroup = this.$store.state.chat.chatGroups.find(
                  (cg) => cg.id === uid
                );

                // あり得ないがチャットグループ未取得の場合は処理中断
                if (!storeChatGroup) break;

                // 割当解除されたユーザーの削除処理はユーザーの購読処理側で行う
                // チャットグループに追加されたユーザーIDを取得
                const addedUids = uids.filter(
                  (newUid) => !storeChatGroup.uids.some((storeUid) => storeUid === newUid)
                );
                addedUids.forEach((addedUid) => {
                  this.subscribeUser(addedUid);
                });

                this.updateChatGroupsNameAndIds({ id: uid, name, uids });
                break;
              }

              case "removed":
                console.log("TODO: removed");
                break;
            }
          });
        },
        () => {
          location.reload();
        }
      );
    },

    // 最終読み取り日時を購読
    subscribeLastReadDatetime(groupId) {
      return onSnapshot(
        query(doc(collection(this.db, "chatGroups", groupId, "lastReadDatetimes"), this.uid)),
        async (snapshot) => {
          const { datetime } = snapshot.data();

          if (datetime) {
            // 最終読み取り日時を設定
            const lastReadDatetime = datetime.toDate();
            this.setLastReadDatetime({
              groupId,
              lastReadDatetime,
            });

            // 未読メッセージ数を設定
            this.setUnreadMessageCount({
              groupId,
              unreadMessageCount: await this.getUnreadMessageCount(groupId),
            });
          }
        },
        () => {
          location.reload();
        }
      );
    },

    // 最新メッセージ購読
    subscribeLatestMessage(groupId) {
      const _query = query(
        collection(this.db, "chatGroups", groupId, "messages"),
        limit(1),
        orderBy("modified", "desc")
      );

      return onSnapshot(
        _query,
        (snapshot) => {
          snapshot.docChanges().forEach(async (change) => {
            const doc = change.doc;

            switch (change.type) {
              // メッセージ追加
              case "modified":
              case "added": {
                let { datetime, message, deleted } = doc.data({ serverTimestamps: "estimate" });
                // メッセージが削除された場合は、最新のメッセージを取得
                if (deleted) {
                  const latestQuery = query(
                    collection(this.db, "chatGroups", groupId, "messages"),
                    where("deleted", "==", null),
                    limit(1),
                    orderBy("datetime", "desc")
                  );
                  const latestDoc = await getDocs(latestQuery);
                  // 削除後に最新のメッセージがない場合は、最新のメッセージストアをリセットして処理終了
                  if (latestDoc.empty) {
                    this.removeLatestMessage({ groupId });

                    // 一応、未読メッセージ数を取得して設定(クライアント側で0としない)
                    this.setUnreadMessageCount({
                      groupId,
                      unreadMessageCount: await this.getUnreadMessageCount(groupId),
                    });
                    break;
                  }

                  ({ datetime, message, deleted } = latestDoc.docs[0].data());
                }

                this.setLatestMessage({ groupId, datetime, message });

                // 未読メッセージ数を設定
                // TODO: メッセージの更新日時と、最終読み取り日時を用いることでリクエストを減らせる → ローカル側で計算可能の意味
                this.setUnreadMessageCount({
                  groupId,
                  unreadMessageCount: await this.getUnreadMessageCount(groupId),
                });
                break;
              }

              case "removed":
                break;
            }
          });
        },
        () => {
          location.reload();
        }
      );
    },

    // チャットグループに属するユーザー情報購読
    subscribeUser(uid) {
      if (
        this.$store.state.user.selfUser.uid === uid ||
        this.$store.state.user.members.some((f) => f.uid === uid) ||
        this.$store.state.user.doctors.some((d) => d.uid === uid)
      ) {
        return;
      }

      // 受け取ったUIDのユーザーの購読を開始
      // チャットグループに追加されていればStoreへ追加し、削除されていればストアから削除 & 購読解除
      const unsubscribe = onSnapshot(
        doc(this.db, "users", uid),
        (snapshot) => {
          const uid = snapshot.id;
          if (
            snapshot.exists() &&
            this.$store.state.chat.chatGroups.some((cg) => cg.uids.some((cgUid) => cgUid === uid))
          ) {
            const userInfo = snapshot.data();
            if (userInfo.type === "member" || userInfo.type === "patient") {
              this.setMember({ uid, member: userInfo });
            } else if (userInfo.type === "doctor") {
              this.setDoctor({ uid, doctor: userInfo });
            }
          } else {
            this.removeUser({ uid });
            this.removeUserUnsubscribe({ uid });
          }
        },
        () => {
          location.reload();
        }
      );
      // 全体の購読解除を行うStoreへ購読解除メソッドを保管
      this.addUserUnsubscribe({ uid, unsubscribe });
    },

    // 未読メッセージ数を設定
    async getUnreadMessageCount(groupId) {
      const chatGroup = this.$store.state.chat.chatGroups.find(
        (chatGroup) => chatGroup.id == groupId
      );
      const coll = collection(this.db, "chatGroups", groupId, "messages");
      const q = query(
        coll,
        where("uid", "!=", this.uid),
        where("deleted", "==", null),
        where("datetime", ">", chatGroup.lastReadDatetime)
      );
      const unreadMessageCount = await getCountFromServer(q);
      return unreadMessageCount.data().count;
    },

    // 各種購読を解除
    unsubscribes() {
      // storeに格納された購読解除を実行
      this.$store.state.unsubscribe.unsubscribes.forEach((unsubscribe) => {
        unsubscribe();
      });
      // ユーザー情報の購読解除を実施
      this.$store.state.unsubscribe.userUnsubscribes.forEach((u) => {
        u.unsubscribe();
      });
    },

    // 戻る
    goBack() {
      switch (this.$route.path.split("/")[1]) {
        case "chat":
          this.$router.push("/chat-group-list").catch(() => {
            console.log("error");
          });
          break;

        case "email-change":
        case "password-change":
          this.$router.push("/setting").catch(() => {
            console.log("error");
          });
          break;

        case "email-change-confirm":
          this.$router.push("/email-change").catch(() => {});
          break;

        case "password-resetting-confirm":
          this.$router.push("/password-resetting").catch(() => {});
          break;

        case "password-resetting":
        case "new-password-setting":
          this.$router.push("/").catch(() => {
            console.log("error");
          });
          break;

        case "chat-group-members": {
          // チャットメンバー一覧から、そのグループチャットに戻るために渡すgroupId
          this.$router.push(`/chat/${this.$route.params.groupId}`).catch(() => {
            console.log("error");
          });
          break;
        }
      }
    },

    // メニュークリック
    onClickMenu(path) {
      // 同一画面への遷移を防止
      const currentPath = this.$route.path.split("/")[1];

      if (currentPath == path) {
        return;
      }

      // チャット画面からチャットグループ一覧への遷移も防止
      if (currentPath == "chat" && path == "chat-group-list") {
        return;
      }

      this.$router.push(`/${path}`).catch(() => {});
    },
    // グループチャット画面からメンバー一覧に画面遷移する
    goChatGroupMembers() {
      if (this.$route.path.split("/")[1] == "chat") {
        const groupId = this.$route.path.split("/")[2];
        this.$router.push({
          path: `/chat-group-members/${groupId}`,
        });
      }
    },

    // アプリの表示状態が変わった際に呼び出されるメソッド
    async onVisibilityChange() {
      if (document.visibilityState === "visible") {
        // // FCM トークンの更新
        await this.updateFcmToken(this.uid, true);
      }
    },

    // 外部サイト移動の確認ダイアログを表示
    confirmExternalSite(targetUrl) {
      this.externalSiteConfirmDialog.targetUrl = targetUrl;
      this.externalSiteConfirmDialog.show = true;
    },
    // 外部サイトへ移動
    toExternalSite() {
      const targetUrl = this.externalSiteConfirmDialog.targetUrl;
      this.externalSiteConfirmDialog.show = false;
      if (targetUrl) {
        window.open(targetUrl, "_blank");
      }
    },
    // お問い合わせページへ遷移
    goToContact() {
      this.confirmExternalSite(
        `${process.env.VUE_APP_GOOGLE_FORMS_CONTACT_URL}?${process.env.VUE_APP_GOOGLE_FORMS_CONTACT_FIELD_NAME_VERSION}=${this.version}`
      );
    },
  },
};
</script>

<style lang="scss">
.loading-overlay {
  z-index: 999 !important;
}
</style>
