<template>
  <validation-observer ref="observer" v-slot="{ invalid }">
    <v-container class="class=px-0">
      <!-- メッセージ -->
      <div class="mb-4">現在のパスワードと、新しいパスワードを入力してください。</div>

      <!-- 現在のパスワード -->
      <div>
        <validation-provider v-slot="{ errors }" name="現在のパスワード" rules="required">
          <v-text-field
            v-model="currentPassword"
            label="現在のパスワード"
            :append-icon="showCurrentPassword ? 'mdi-eye' : 'mdi-eye-off'"
            :type="showCurrentPassword ? 'text' : 'password'"
            outlined
            dense
            :error-messages="errors"
            @click:append="showCurrentPassword = !showCurrentPassword"
          >
          </v-text-field>
        </validation-provider>
      </div>

      <!-- 新しいパスワード -->
      <div>
        <validation-provider v-slot="{ errors }" name="新しいパスワード" rules="required|password">
          <v-text-field
            v-model="newPassword"
            label="新しいパスワード"
            :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
            :type="showPassword ? 'text' : 'password'"
            outlined
            dense
            :error-messages="errors"
            @click:append="showPassword = !showPassword"
          >
          </v-text-field>
        </validation-provider>
      </div>

      <!-- 新しいパスワード（確認用） -->
      <div>
        <validation-provider
          v-slot="{ errors }"
          name="新しいパスワード（確認用）"
          :rules="`required|password_confirm:${newPassword}`"
        >
          <v-text-field
            v-model="newPasswordConfirm"
            label="新しいパスワード（確認用）"
            :append-icon="showPasswordConfirm ? 'mdi-eye' : 'mdi-eye-off'"
            :type="showPasswordConfirm ? 'text' : 'password'"
            outlined
            dense
            :error-messages="errors"
            @click:append="showPasswordConfirm = !showPasswordConfirm"
          >
          </v-text-field>
        </validation-provider>
      </div>

      <!-- 変更ボタン -->
      <v-btn color="primary" elevation="0" block :disabled="invalid" @click="passwordChange">
        変更
      </v-btn>
    </v-container>
  </validation-observer>
</template>

<script>
import { MESSAGES } from "@/const/message";
import errorHandlerMixin from "@/mixins/errorHandlerMixin";
import { mapActions } from "vuex";
export default {
  name: "PasswordChange",
  mixins: [errorHandlerMixin()],
  data: () => ({
    // 現在のパスワード
    currentPassword: "",
    // 新しいパスワード
    newPassword: "",
    // 新しいパスワード（確認用）
    newPasswordConfirm: "",

    // パスワード表示制御
    showCurrentPassword: false,
    showPassword: false,
    showPasswordConfirm: false,
  }),
  methods: {
    ...mapActions("api", ["incrementRunningApiCount", "decrementRunningApiCount"]),

    async passwordChange() {
      this.incrementRunningApiCount();

      try {
        const func = this.$httpsCallable(this.$functions, "passwordchange");
        await func({
          currentPassword: this.currentPassword,
          newPassword: this.newPassword,
          newPasswordConfirm: this.newPasswordConfirm,
        });

        this.openSnackbar({ text: "パスワードを変更しました" });

        this.decrementRunningApiCount();

        this.$router.push("/setting").catch(() => {});
      } catch (error) {
        let message = "";
        switch (error.message) {
          case "auth/invalid-credential":
            message = MESSAGES.ERRORS.PASSWORD_WRONG;
            break;
          default:
            message = MESSAGES.ERRORS.PASSWORD_CHANGE;
        }
        this.showError(message);
        this.decrementRunningApiCount();
      }
    },
  },
};
</script>
