<template>
  <v-container class="px-2 h-full flex-col">
    <template v-if="patient">
      <!-- 患者 -->
      <div>患者</div>
      <div class="px-10 mb-4">
        <div class="flex gap-4 items-center">
          <!-- アイコン -->
          <v-avatar size="70">
            <v-img :src="require(`../../assets/avatar/default/primary.svg`)"> </v-img>
          </v-avatar>
          <div>
            <span>{{ patient.lastName }} {{ patient.firstName }}</span>
          </div>
          <user-type-label
            v-if="!isMe(patient.uid)"
            :labelType="patient.labelType ?? ''"
            :labelTypeOther="patient.labelTypeOther ?? ''"
          />
        </div>
      </div>
    </template>

    <!-- メンバー一覧 -->
    <template v-if="(members?.length ?? 0) > 0">
      <div>メンバー</div>
      <div v-for="(member, index) in members" :key="`member-${index}`" class="px-10 mb-4">
        <div class="flex gap-4 items-center">
          <!-- アイコン -->
          <v-avatar size="70">
            <v-img :src="require(`../../assets/avatar/default/primary.svg`)"> </v-img>
          </v-avatar>
          <div>
            <span>{{ member.lastName }} {{ member.firstName }}</span>
          </div>
          <user-type-label
            v-if="!isMe(member.uid)"
            :labelType="member.labelType ?? ''"
            :labelTypeOther="member.labelTypeOther ?? ''"
          />
        </div>
      </div>
    </template>

    <!-- 医師一覧 -->
    <template v-if="(doctors?.length ?? 0) > 0">
      <div>医師</div>
      <div v-for="(doctor, index) in doctors" :key="`doctor-${index}`" class="px-10 mb-4">
        <div class="flex gap-4 items-center">
          <!-- アイコン -->
          <v-avatar size="70">
            <v-img :src="require(`../../assets/avatar/default/primary.svg`)"> </v-img>
          </v-avatar>
          <div>
            <span>{{ doctor.lastName }} {{ doctor.firstName }}</span>
          </div>
          <user-type-label
            v-if="!isMe(doctor.uid)"
            :labelType="doctor.labelType ?? ''"
            :labelTypeOther="doctor.labelTypeOther ?? ''"
          />
        </div>
      </div>
    </template>
  </v-container>
</template>

<script>
import UserTypeLabel from "../atoms/UserTypeLabel.vue";

export default {
  name: "ChatGroupMembers",
  components: { UserTypeLabel },
  props: {
    groupId: {
      required: true,
      type: String,
    },
  },
  computed: {
    chatGroup() {
      const { chatGroups } = this.$store.state.chat;

      if (!chatGroups) return null;

      return chatGroups.find((cg) => cg.id === this.groupId);
    },
    patient() {
      const chatGroup = this.chatGroup;

      if (!chatGroup) return null;

      if (this.$store.state.user.selfUser.uid === chatGroup.patientUid) {
        const user = this.$store.state.user.selfUser;
        return user;
      }

      const user = this.$store.state.user?.members?.find((m) => m.uid === chatGroup.patientUid);
      return user;
    },
    members() {
      const chatGroup = this.chatGroup;

      if (!chatGroup) return null;

      const members = this.$store.state.user?.members?.filter(
        (m) => chatGroup.uids.includes(m.uid) && chatGroup.patientUid !== m.uid
      );

      const selfUserId = this.$store.state.user?.selfUser.uid;
      if (chatGroup.patientUid !== selfUserId && chatGroup.uids.includes(selfUserId)) {
        members.unshift(this.$store.state.user?.selfUser);
      }

      return members;
    },

    doctors() {
      const chatGroup = this.chatGroup;

      if (!chatGroup) return null;

      return this.$store.state.user?.doctors?.filter((m) => chatGroup.uids.includes(m.uid));
    },
  },
  methods: {
    // UID が自身かどうかを判定
    isMe(uid) {
      return uid === this.$store.state.user.selfUser?.uid;
    },
  },
};
</script>

<style lang="scss" scoped></style>
