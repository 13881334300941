<template>
  <v-container ref="message-container" class="h-full py-0">
    <!-- メッセージ -->
    <div class="pt-2 space-y-4" style="height: calc(100% - 73px)">
      <!-- もっとメッセージを読み込む -->
      <div v-if="messages.length > 0 && !isLoadedAllMessage" class="text-center">
        <v-btn color="primary" text @click="loadPastMessages"> もっとメッセージを読み込む </v-btn>
      </div>

      <!-- メッセージ -->
      <div
        v-for="({ id, uid, message, datetime, deleted }, index) in displayMessages"
        :key="id"
        class="gap-4"
      >
        <!-- 日付（区切り）ラベル -->
        <div v-if="checkDateChanged(index)" class="mb-2 text-center">
          <v-chip class="px-4" color="primary" small>{{ getDividerDateLabel(datetime) }}</v-chip>
        </div>

        <div class="flex gap-4" :class="{ 'justify-end': isMe(uid) }">
          <!-- アイコン -->
          <v-avatar v-if="!isMe(uid)" class="mt-2" size="40">
            <v-img src="../../assets/avatar/default/primary.svg"> </v-img>
          </v-avatar>

          <!-- ユーザ名・投稿日時・メッセージ -->
          <div class="relative">
            <div
              class="absolute whitespace-nowrap text-sm text-primary"
              :class="{ 'end-0': isMe(uid) }"
            >
              <!-- ユーザー名 -->
              <!-- TODO: 暫定対応 labelType の undefined 対策. 根本的な対応としては、購読のタイミングを修正する必要がある？ -->
              <user-type-label
                v-if="!isMe(uid)"
                :labelType="users[uid]?.labelType ?? ''"
                :labelTypeOther="users[uid]?.labelTypeOther"
              />
              <span v-if="!isMe(uid)" class="mr-1">
                {{ userNames[uid] || "削除されたユーザー" }}
              </span>

              <!-- 投稿日時 -->
              <span class="text-xs text-gray-400">
                {{ formatDatetime(datetime) }}
              </span>

              <!-- 既読数 -->
              <span v-if="isMe(uid) && !deleted" class="ml-2 text-xs text-gray-400">
                既読 {{ getReadCount(datetime) }}
              </span>

              <!-- 削除ボタン -->
              <v-btn v-if="isMe(uid) && !deleted" x-small icon @click="onClickDelete(id)">
                <v-icon color="grey" small>mdi-delete</v-icon>
              </v-btn>
            </div>

            <!-- メッセージ -->
            <div
              class="mt-6 px-4 py-3 rounded-lg whitespace-pre-line break-all"
              :class="{
                'l-comment': !isMe(uid) && !deleted,
                'bg-blue-100': isMe(uid) && !deleted,
                'bg-gray-100 text-gray-400': deleted,
              }"
            >
              {{ deleted ? getDeletedMessage(uid) : message }}
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- 新しいメッセージ -->
    <div v-show="!isScrolledToBottom && hasNewMessage" class="sticky" style="bottom: 128px">
      <div class="w-full text-center">
        <v-btn icon @click="scrollTo()">
          <v-icon class="bg-white" color="primary" size="40"> mdi-arrow-down-bold-circle </v-icon>
        </v-btn>
      </div>
    </div>

    <!-- メッセージ入力・送信 -->
    <validation-provider v-slot="{ errors }" rules="max:500">
      <div class="sticky mt-4 py-2 bg-white" style="bottom: 56px">
        <div class="flex items-center space-x-2">
          <!-- メッセージ入力 -->
          <v-textarea
            v-model="inputMessage"
            rows="1"
            auto-grow
            outlined
            dense
            hide-details
            :error-messages="errors"
          >
          </v-textarea>

          <!-- 送信ボタン -->
          <v-btn
            color="primary"
            height="40"
            elevation="0"
            :disabled="!inputMessage || errors?.length > 0 || isProcessing"
            @click="postMessage"
          >
            送信
          </v-btn>
        </div>
      </div>
    </validation-provider>

    <!-- メッセージ削除確認ダイアログ -->
    <v-dialog v-model="messageDeleteConfirmDialog.show" max-width="480">
      <confirm-dialog
        v-if="messageDeleteConfirmDialog.show"
        title="メッセージを削除しますか？"
        message="削除したメッセージは元に戻せません"
        okButtonLabel="削除"
        okButtonColor="red darken-1"
        :isProcessing="isProcessing"
        @ok="deleteMessage"
        @cancel="messageDeleteConfirmDialog.show = false"
      ></confirm-dialog>
    </v-dialog>

    <!-- 削除完了ダイアログ -->
    <v-dialog v-model="deleteCompleteDialog.show" max-width="400">
      <message-dialog
        v-if="deleteCompleteDialog.show"
        title="メッセージを削除しました"
        @close="deleteCompleteDialog.show = false"
      ></message-dialog>
    </v-dialog>
  </v-container>
</template>

<script>
import UserTypeLabel from "@/components/atoms/UserTypeLabel";
import Confirm from "@/components/dialogs/Confirm";
import Message from "@/components/dialogs/Message";
import { GENDER } from "@/const/const";
import { getAuth } from "firebase/auth";
import {
  addDoc,
  collection,
  doc,
  getDocs,
  getFirestore,
  limit,
  onSnapshot,
  orderBy,
  query,
  serverTimestamp,
  setDoc,
  startAfter,
  Timestamp,
  where,
} from "firebase/firestore";
import moment from "moment";
import { mapActions } from "vuex";

// メッセージの読み込み量（初回）
const INITIAL_LOAD_MESSAGE_COUNT = 20;

// メッセージの読み込み量（過去）
const PAST_LOAD_MESSAGE_COUNT = 10;

export default {
  name: "Chat",
  components: { ConfirmDialog: Confirm, MessageDialog: Message, UserTypeLabel },
  props: {
    groupId: {
      required: true,
      type: String,
    },
  },
  data: () => ({
    // メッセージコンテナ
    messageContainer: null,

    // メッセージ
    messages: [],

    // 入力メッセージ
    inputMessage: "",

    // 最下部までスクロールされたか
    isScrolledToBottom: true,

    // 自動スクロール
    autoScroll: false,

    // 過去のメッセージを全て読み込んだか
    isLoadedAllMessage: false,

    // 新しいメッセージの有無
    hasNewMessage: false,

    // UID
    uid: null,

    // データベース
    db: null,

    // 一番上のドキュメント（メッセージ）
    topDoc: null,

    // 一番下のドキュメント（メッセージ）
    bottomDoc: null,

    // メッセージの購読解除
    unsubscribe: null,

    // 最終読み取り日時
    lastReadDatetimes: {},

    // 最終読み取り日時の購読解除
    unsubscribeLastReadDatetimes: null,

    // 削除ダイアログ
    messageDeleteConfirmDialog: {
      show: false,
      messageUid: null,
    },

    // 削除完了ダイアログ
    deleteCompleteDialog: {
      show: false,
    },

    // 性別
    GENDER,

    // 画面が表示されているかどうか
    displayed: true,

    // 処理中フラグ
    isProcessing: false,
  }),
  computed: {
    // ユーザー名（自身 + メンバー + 医師）
    userNames() {
      const userNames = {};
      const { uid, firstName, lastName } = this.$store.state.user.selfUser;
      userNames[uid] = `${lastName} ${firstName}`;

      this.$store.state.user.members.forEach(({ uid, firstName, lastName }) => {
        userNames[uid] = `${lastName} ${firstName}`;
      });

      this.$store.state.user.doctors.forEach(({ uid, firstName, lastName }) => {
        userNames[uid] = `${lastName} ${firstName}`;
      });

      return userNames;
    },

    users() {
      const users = {};

      const { uid, gender, labelType, labelTypeOther } = this.$store.state.user.selfUser;
      users[uid] = {
        gender,
        labelType,
        labelTypeOther,
      };

      this.$store.state.user.members.forEach(({ uid, gender, labelType, labelTypeOther }) => {
        users[uid] = {
          gender,
          labelType,
          labelTypeOther,
        };
      });

      this.$store.state.user.doctors.forEach(({ uid, gender, labelType, labelTypeOther }) => {
        users[uid] = {
          gender,
          labelType,
          labelTypeOther,
        };
      });

      return users;
    },

    // 表示するメッセージ一覧(自分の削除済みメッセージは表示しない)
    displayMessages() {
      return this.messages.filter((m) => {
        if (m.deleted && this.isMe(m.uid)) {
          return false;
        } else {
          return true;
        }
      });
    },
  },
  mounted() {
    // スクロールイベントを登録
    window.addEventListener("scroll", this.handleScroll);

    // 開いたチャットグループの通知を削除
    this.closeNotification();
  },
  created() {
    // UID を取得しメッセージを読み込む
    const auth = getAuth();

    this.uid = auth.currentUser.uid;

    // メッセージ読み込み（初回）
    this.db = getFirestore();
    this.loadInitialMessages();

    // 通知削除
    this.resetNotification();

    // 最終読み取り日時を更新
    this.updateLastReadDatetimes();

    // 最終読み取り日時の購読
    this.unsubscribeLastReadDatetimes = this.subscribeLastReadDatetimes();

    // アプリの表示状態が変わった際に呼び出されるイベントリスナーを追加
    document.addEventListener("visibilitychange", this.onVisibilityChange);
  },
  destroyed() {
    // スクロールイベントを破棄
    window.removeEventListener("scroll", this.handleScroll);

    // メッセージの購読解除
    if (this.unsubscribe) {
      this.unsubscribe();
    }

    // 最終読み取り日時の購読解除
    if (this.unsubscribeLastReadDatetimes) {
      this.unsubscribeLastReadDatetimes();
    }

    // アプリの表示状態が変わった際に呼び出されるイベントリスナーを削除
    document.removeEventListener("visibilitychange", this.onVisibilityChange);
  },
  methods: {
    ...mapActions("api", ["incrementRunningApiCount", "decrementRunningApiCount"]),

    // スクロールイベント
    handleScroll() {
      // 最下部までスクロールされたか判定
      const scrollTop = Math.ceil(window.scrollY);
      const scrollHeight = document.documentElement.scrollHeight;
      const clientHeight = window.innerHeight;
      this.isScrolledToBottom = scrollTop + clientHeight >= scrollHeight;

      // 最下部までスクロールされた場合は新しいメッセージの有無を非表示
      if (this.isScrolledToBottom) {
        this.hasNewMessage = false;
      }
    },

    // メッセージ読み込み（初回）
    async loadInitialMessages() {
      this.incrementRunningApiCount();

      // 一番古いメッセージまで読み込んだかの確認のため、上限 + 1まで取得
      const _query = query(
        collection(this.db, "chatGroups", this.groupId, "messages"),
        where("deleted", "==", null),
        limit(INITIAL_LOAD_MESSAGE_COUNT + 1),
        orderBy("datetime", "desc")
      );

      const documentSnapshots = await getDocs(_query);
      const { docs = [] } = documentSnapshots;

      // 上限の数までしか取得していない場合は全て読み込み済み
      // それ以外の場合は、まだメッセージがある
      // 配列の先頭は本来読み込まないメッセージなので、取り出しておく
      if (docs.length <= INITIAL_LOAD_MESSAGE_COUNT) {
        this.isLoadedAllMessage = true;
      } else {
        docs.splice(-1);
      }

      docs.forEach((doc) => {
        this.messages.unshift({ id: doc.id, ...doc.data() });
      });

      if (docs.length > 0) {
        this.topDoc = docs[docs.length - 1];
        this.bottomDoc = docs[0];
      }

      // 最下部までスクロール
      this.scrollTo();

      // メッセージ購読を開始
      this.unsubscribe = await this.subscribeMessage();

      this.decrementRunningApiCount();
    },

    // メッセージ購読
    async subscribeMessage() {
      // サーバー時刻を取得
      const func = this.$httpsCallable(this.$functions, "getservertime");
      const res = await func();
      const _query = query(
        collection(this.db, "chatGroups", this.groupId, "messages"),
        where("modified", ">", Timestamp.fromMillis(res.data))
      );

      return onSnapshot(
        _query,
        (snapshot) => {
          snapshot.docChanges().forEach((change) => {
            const doc = change.doc;

            switch (change.type) {
              // メッセージ追加
              case "added": {
                // 自分が投稿したメッセージの場合、modifiedじゃなくremoved → addedの順にイベントが発生する
                // addedにて変更を反映する
                const message = doc.data();
                const isDeleted = message.deleted !== null;
                if (isDeleted) {
                  const index = this.messages.findIndex((m) => m.id === doc.id);
                  // メッセージが取得できない = 未取得の場合は処理無し
                  if (index === -1) {
                    break;
                  }
                  this.messages.splice(index, 1, {
                    id: doc.id,
                    ...message,
                  });
                  break;
                }

                this.messages.push({
                  id: doc.id,
                  ...doc.data({ serverTimestamps: "estimate" }),
                });

                // スクロールバーが最下部の場合は最下部までスクロール
                if (this.isScrolledToBottom) {
                  this.scrollTo();
                } else {
                  // スクロール途中かつ、他人のメッセージを取得した際は新着メッセージの有無を表示
                  if (!this.isMe(doc.data().uid)) {
                    this.hasNewMessage = true;
                  }
                }

                // 画面が表示状態であれば最終読み取り日時を更新
                if (this.displayed) {
                  this.updateLastReadDatetimes();
                  // 通知を削除
                  this.resetNotification();
                }

                break;
              }

              case "modified": {
                // メッセージ編集 & 自分で投稿したデータのFirestoreへの書き込み終了時
                const index = this.messages.findIndex((m) => m.id === doc.id);
                if (index != -1) {
                  this.messages.splice(index, 1, {
                    id: doc.id,
                    ...doc.data(),
                  });
                }
                break;
              }
            }
          });
        },
        () => {
          location.reload();
        }
      );
    },

    // 過去メッセージ読み込み
    async loadPastMessages() {
      this.incrementRunningApiCount();

      // 一番古いメッセージまで読み込んだかの確認のため、上限 + 1まで取得
      const _query = query(
        collection(this.db, "chatGroups", this.groupId, "messages"),
        where("deleted", "==", null),
        limit(PAST_LOAD_MESSAGE_COUNT + 1),
        orderBy("datetime", "desc"),
        startAfter(this.topDoc)
      );

      // スクロール位置をキープするため、読み込み前のコンテナの高さを保持しておく
      const beforeScrollHeight = document.documentElement.scrollHeight;

      const documentSnapshots = await getDocs(_query);
      const { docs = [] } = documentSnapshots;

      // 上限の数までしか取得していない場合は全て読み込み済み
      // それ以外の場合は、まだメッセージがある
      // 配列の先頭は本来読み込まないメッセージなので、取り出しておく
      if (docs.length <= PAST_LOAD_MESSAGE_COUNT) {
        this.isLoadedAllMessage = true;
      } else {
        docs.splice(-1);
      }

      docs.forEach((doc) => {
        this.messages.unshift({ id: doc.id, ...doc.data() });
      });

      if (docs.length > 0) {
        this.topDoc = docs[docs.length - 1];

        // スクロール位置を復元
        this.$nextTick(() => {
          this.scrollTo(document.documentElement.scrollHeight - beforeScrollHeight);
        });
      }

      this.decrementRunningApiCount();
    },

    // 指定された位置までスクロール（位置の指定がない場合は最下部までスクロール）
    scrollTo(top) {
      this.autoScroll = true;

      this.$nextTick(() => {
        window.scrollTo({
          top: top ? top : document.documentElement.scrollHeight,
          behavior: "auto",
        });

        setTimeout(() => {
          this.autoScroll = false;
        }, 500);
      });
    },

    // 最終読み取り日時を更新
    async updateLastReadDatetimes() {
      await setDoc(
        doc(collection(this.db, "chatGroups", this.groupId, "lastReadDatetimes"), this.uid),
        {
          datetime: serverTimestamp(),
        }
      );
    },

    // 最終読み取り日時の購読
    // TODO: App.vue でも最終読み取り日時を購読しているため、watch で store を監視できないか要検討 → 管理側で出来ているので出来そう
    subscribeLastReadDatetimes() {
      const _query = query(collection(this.db, "chatGroups", this.groupId, "lastReadDatetimes"));

      return onSnapshot(
        _query,
        (snapshot) => {
          snapshot.docChanges().forEach((change) => {
            // 最終読み取り日時を設定
            const doc = change.doc;
            const { datetime } = doc.data();

            if (datetime) {
              this.lastReadDatetimes[doc.id] = datetime.toDate();

              // 自身のメッセージを再設定することで既読数を更新
              this.messages.forEach((message, index) => {
                if (this.isMe(message.uid)) {
                  this.messages.splice(index, 1, message);
                }
              });
            }
          });
        },
        () => {
          location.reload();
        }
      );
    },

    // UID が自身かどうかを判定
    isMe(uid) {
      return uid === this.uid;
    },

    // 日付が変わっている箇所かどうかを判定
    checkDateChanged(index) {
      // 過去のメッセージを全て読み込んだ場合
      if (this.isLoadedAllMessage && index == 0) {
        return true;
      }

      if (index == 0) {
        return false;
      }

      const messageDate = moment(this.messages[index].datetime.toDate());
      const prevMessageDate = moment(this.messages[index - 1].datetime.toDate());

      // メッセージと1つ上のメッセージが同じ日付でない場合trueを返す
      return !messageDate.isSame(prevMessageDate, "day");
    },

    // 日付（区切り）ラベル取得
    getDividerDateLabel(value) {
      const datetime = moment(value?.toDate()).startOf("day");
      const today = moment().startOf("day");

      switch (today.diff(datetime, "day")) {
        case 0:
          return "今日";

        case 1:
          return "昨日";

        default:
          return datetime.format("M/D (ddd)");
      }
    },

    // 日時フォーマット
    formatDatetime(value) {
      return moment(value?.toDate()).format("HH:mm");
    },

    // 既読数を取得
    getReadCount(datetime) {
      // 自身以外の最終読み取り日時から既読数を計算
      let readCount = 0;
      Object.keys(this.lastReadDatetimes).forEach((key) => {
        // 自身の最終読み取り日時は既読計算に含めない
        if (this.isMe(key)) {
          return;
        }

        const messageDate = moment(datetime.toDate());
        const lastReadDate = moment(this.lastReadDatetimes[key]);

        if (lastReadDate.isSameOrAfter(messageDate)) {
          readCount++;
        }
      });
      return readCount;
    },

    // メッセージ送信
    async postMessage() {
      try {
        this.isProcessing = true;
        this.incrementRunningApiCount();

        await addDoc(collection(this.db, "chatGroups", this.groupId, "messages"), {
          uid: this.uid,
          message: this.inputMessage,
          datetime: serverTimestamp(),
          deleted: null,
          modified: serverTimestamp(),
        });

        // メッセージをクリア
        this.inputMessage = "";

        // 最下部までスクロール
        this.hasNewMessage = false;
        this.scrollTo();
      } finally {
        this.decrementRunningApiCount();
        this.isProcessing = false;
      }
    },

    // 削除ボタン押下
    onClickDelete(uid) {
      this.messageDeleteConfirmDialog.show = true;
      this.messageDeleteConfirmDialog.messageUid = uid;
    },

    // メッセージを削除
    async deleteMessage() {
      try {
        this.isProcessing = true;
        this.incrementRunningApiCount();

        // メッセージを削除
        const { messageUid } = this.messageDeleteConfirmDialog;

        const func = this.$httpsCallable(this.$functions, "deletemessage");
        await func({
          chatGroupUid: this.groupId,
          messageUid: messageUid,
        });

        // 削除完了ダイアログを表示
        this.deleteCompleteDialog.show = true;
      } catch {
        // TODO: エラーハンドリング
        alert("メッセージの削除に失敗しました");
      } finally {
        // メッセージ削除確認ダイアログを閉じる
        this.messageDeleteConfirmDialog.show = false;
        this.messageDeleteConfirmDialog.messageUid = null;
        this.decrementRunningApiCount();
        this.isProcessing = false;
      }
    },

    async resetNotification() {
      if (window.navigator.userAgent.indexOf("WV_app") != -1) {
        await window.flutter_inappwebview.callHandler(
          "resetNotificationByChatGroupId",
          this.groupId
        );
      }
    },

    // アプリの表示状態が変わった際に呼び出されるメソッド
    async onVisibilityChange() {
      if (document.visibilityState === "visible") {
        // 最新のメッセージが未読であれば、最終既読日時を更新
        if (this.messages && this.messages.length > 0) {
          const lastRead = moment(this.lastReadDatetimes[this.uid]);
          const lastMessage = moment(this.messages[this.messages.length - 1].datetime.toDate());

          if (lastMessage.isSameOrAfter(lastRead)) {
            await this.updateLastReadDatetimes();
          }
        }

        // 開いたチャットグループの通知を削除
        await this.closeNotification();

        this.displayed = true;
      } else {
        this.displayed = false;
      }
    },

    // 開いたチャットグループの通知を削除する
    async closeNotification() {
      const serviceWorker = await navigator.serviceWorker.getRegistration(
        "/firebase-cloud-messaging-push-scope"
      );

      if (serviceWorker) {
        const notifications = await serviceWorker.getNotifications({ tag: this.groupId });
        for (const n of notifications) {
          n.close();
        }
      }
    },

    // 削除済みメッセージ表示取得
    getDeletedMessage(uid) {
      return `${this.userNames[uid]}がメッセージを削除しました`;
    },
  },
};
</script>

<style lang="scss" scoped>
/* 吹き出しデザイン */
.l-comment {
  position: relative;
  background: rgb(241 245 249);
}

.l-comment:after {
  content: "";
  position: absolute;
  top: 3px;
  left: -19px;
  border: 8px solid transparent;
  border-right: 18px solid rgb(241 245 249);
  transform: rotate(35deg);
  -webkit-transform: rotate(35deg);
}
</style>
